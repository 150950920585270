module.exports = {
  content: [
    './app/helpers/**/*.rb',
    './app/javascript/**/*.js',
    './app/views/**/*',
  ],
  purge: {
    enabled: true,
    content: ['./app/views/**/*'],
    options: {
      keyframes: true,
    },
  },
  theme: {
    extend: {
      colors: {
        change: 'black',
      },
    },
  },
  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/typography')
  ],
}